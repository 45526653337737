
import useDcnTemplateSet from '@/hooks/payment/EntitySiPayout/useDcnTemplateSet'
import { defineComponent, ref } from 'vue'
import MovablePanel from '@/views/Payment/components/Movable/MovablePanel.vue'
import Movable from '@/views/Payment/components/Movable/Movable.vue'
import { message } from "ant-design-vue"
import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
export default defineComponent({
  components: {
    MovablePanel,
    Movable,
    EntitySelector
  },
  setup() {
    const {
      PANEL_WIDTH,
      PANEL_HEIGHT,
      searchParams,
      // entities,
      state,
      slotSort,
      wrapperStyles,
      fileNameRef,
      fileCodeRef,
      rateRef,
      dealer400CodeRef,
      partyARef,
      partyBRef,
      protocolSpecificationRef,
      assessmentPeriodRef,
      detailTableRef,
      additionSpecificationRef,
      partyASignRef,
      partyBSignRef,
      computeLayout,
      saveDcnTmplData,
    } = useDcnTemplateSet()
    const previewPanelVisiable = ref(false)

    const doPreview = () => {
      previewPanelVisiable.value = true
    }

    const doClosePreviewPanel = () => {
      previewPanelVisiable.value = false
    }

    const onSaveData = async() => {
      await saveDcnTmplData()
      message.success('Save successfully!')
    }

    const onSave = async() => {
      computeLayout()
      await saveDcnTmplData()
      previewPanelVisiable.value = false
      message.success('Save successfully!')
    }

    return {
      PANEL_WIDTH,
      PANEL_HEIGHT,
      searchParams,
      // entities,
      state,
      previewPanelVisiable,
      doPreview,
      doClosePreviewPanel,
      slotSort,
      wrapperStyles,
      fileNameRef,
      fileCodeRef,
      rateRef,
      dealer400CodeRef,
      partyARef,
      partyBRef,
      protocolSpecificationRef,
      assessmentPeriodRef,
      detailTableRef,
      additionSpecificationRef,
      partyASignRef,
      partyBSignRef,
      onSaveData,
      onSave      
    }
  }
})
